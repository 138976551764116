<template>
  <div>
    <div class="quoteSuccess">
      <img :src="img">
      <p>酒店报价成功</p>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      img: require('../../assets/img/paySuccess.png')
    }
  }
}
</script>
<style lang="scss" scoped>
.quoteSuccess {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 2rem;
    height: 2rem;
  }
  p {
    font-size: 0.3rem;
    font-weight: bold;
  }
}
</style>
